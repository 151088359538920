@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: url(./Assets/fonts/nunito.ttf) format("truetype");
}

@font-face {
  font-family: "Speda";
  font-style: normal;
  font-weight: 400;
  src: url(./Assets/fonts/Speda.ttf) format("truetype");
}

@font-face {
  font-family: "Rudaw";
  font-style: normal;
  font-weight: 400;
  src: url(./Assets/fonts/rudawregular2.ttf) format("truetype");
}

* {
  font-family: Nunito, Speda, Rudaw, sans-serif;
}

body {
  margin: 0;
  font-family: "Speda", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Nunito, Speda, Rudaw, sans-serif !important;
}

.info-area .btn-primary {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  border-radius: 50% !important;
  box-shadow: none !important;
  color: transparent !important;
}
.info-area .btn-primary:focus {
  outline: none !important;
  border: none !important;
  color: transparent !important;
  box-shadow: none !important;
}
