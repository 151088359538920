.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mean-container .mean-nav {
  margin-top: 0 !important;
}
.changeLanguage li a:hover {
  cursor: pointer !important;
}
.mobileNavbar .navbar-collapse {
  position: fixed;
  top: 0;
  left: 0;
  padding: 25px;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to bottom right, #22384d, #22384d);
  z-index: 600;
  transform: translate(0%, 0%);
  text-align: center;
}
.mobileNavbar {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 500;
  width: 100%;
  background-color: transparent !important;
}
.mobileNavbar + .bg-light {
  background-color: transparent !important;
}
.allNavs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.nav-link {
  color: #68707b;
}
.allNavs .nav-link {
  color: white;
}
.stickyAndWhiteBG {
  position: fixed !important;
  top: 0;
  background-color: #00689b !important;
  transition: all 0.5s ease-in-out !important;
}
.collapsing {
  -webkit-transition: none;
  transition: none;
}
.btn:focus {
  outline: none !important;
}

@media only screen and (max-width: 600px) {
  .logo img {
    width: 100px !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00689b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal {
  background-color: #00679b65;
}
.modal-content {
  border: none;
  border-radius: 17px;
}
.modal-title {
  color: #00689b !important;
}
