.dropdown-menu-rtl::before {
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  border-top: 0;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ffffff;
  border-left: 8px solid transparent;
  top: -8px;
  right: 20px !important;
  z-index: -11;
}
/* footer */
.footer-area-rtl .content h3::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 100%;
  background: #20b6b8;
  right: -15px !important;
  z-index: -1;
}
/* header */
.header-content-rtl {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  float: left !important;
}
.header-area .header-content-rtl .header-contact::before {
  position: absolute;
  width: 1px;
  height: 24px;
  content: "";
  background: #1b96d3;
  left: -62px !important;
  right: 0;
  top: 2px;
}
.header-area .header-content-rtl .header-contact {
  direction: ltr !important;
}

.closeButtonRTL {
  margin: 0 !important;
}
