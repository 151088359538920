.swiper-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 75%;
  height: 400px;
  object-fit: contain;
  object-position: center;
  margin: 0 auto;
  padding: 20px;
  filter: grayscale(100%);
  transition: all 0.5s ease-in;
}

.home-team-slider:hover p {
  color: white !important;
}
.home-team-slider:hover img {
  color: white !important;
  filter: grayscale(0%);
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  border-radius: 6px;
  margin-right: 7px;
  transition: all 0.5s ease-in-out;
  background-color: blue;
}
.swiper-pagination-bullet-active {
  width: 50px;
  transition: all 0.5s ease-in-out;
  background-color: red;
}
