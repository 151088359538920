.login {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: blue !important;
}
.loginForm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 360px;
  background-color: white;
  color: black !important;
}

.radius {
  border-radius: 7px !important;
}
.table > tbody {
  vertical-align: baseline !important;
}
.table td img {
  width: 275px;
  height: 180px;
  object-fit: contain;
}
